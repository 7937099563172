<template>
	<!-- template is intentionally empty -->
	<div />
</template>
<script>
import { mapGetters } from 'vuex'
import { downloadScanFile } from '../../../../../../shared/utils/api/site-scanner.js'
import { downloadTypeEnum } from './download-type-enums.js'
import downloadCSVMixin from '../../../../../../shared/mixins/download-csv.js'
import { SITE_SCANNER_READ_ONLY } from '../../../../../../shared/permissions/admin-portal-permissions.js'
export default {
	mixins: [downloadCSVMixin],
	props: {
		scanGuid: String,
		cookiename: String
	},
	setup () {
		return {
			downloadTypeEnum
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		userCanUseSiteScanner () {
			return this.productAreaPermission(SITE_SCANNER_READ_ONLY)
		}
	},
	created () {
		this.downloadCsv()
	},
	methods: {
		async downloadCsv () {
			if (this.userCanUseSiteScanner) {
				const response = await downloadScanFile(this.scanGuid, downloadTypeEnum.COOKIE_REPORT, this.cookiename)
				const fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0]
				this.generateCsv(response.data, fileName)
			}
			this.backToOverview()
		},
		backToOverview () {
			this.$router.push({ name: 'cookie-scanner' })
		}
	}
}
</script>
